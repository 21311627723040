"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAppData = exports.getLocales = void 0;
var API_ONE_APPLICATION = process.env.API_ONE_APPLICATION || '';
var API_KEY = process.env.API_KEY || '';
var APPLICATION_ID = process.env.APPLICATION_ID || '';
var headers = new Headers();
headers.append('X-Header-AppId', APPLICATION_ID);
headers.append('X-Header-ApiKey', API_KEY);
headers.append('Content-Type', 'application/json');
if (location.origin.includes('.stag.') ||
    location.origin.includes('localhost') ||
    location.origin.includes('.hlx.')) {
    headers.append('aws-cf-cd-onecx-cache', 'disabled');
}
var getLocales = function () {
    return fetch("".concat(API_ONE_APPLICATION, "/locales"), { headers: headers, method: 'GET' }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetLocales request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getLocales = getLocales;
var getAppData = function (localeId) {
    return fetch("".concat(API_ONE_APPLICATION, "/applications/locale/").concat(localeId), {
        //return fetch(`${API_ONE_APPLICATION}/applications/sections`, {
        headers: headers,
        method: 'GET'
    }).then(function (response) {
        if (!response.ok) {
            return Promise.reject(new Error("GetAppData request failed: ".concat(response.statusText)));
        }
        return response.json();
    });
};
exports.getAppData = getAppData;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseUrl = exports.urlToConfig = exports.configToUrl = void 0;
var configToUrl = function (features) {
    return encodeURIComponent(btoa(JSON.stringify(features)));
};
exports.configToUrl = configToUrl;
var urlToConfig = function (url) {
    return JSON.parse(atob(decodeURIComponent(url)));
};
exports.urlToConfig = urlToConfig;
var getBaseUrl = function () {
    return window.location.href.split('?')[0];
};
exports.getBaseUrl = getBaseUrl;
